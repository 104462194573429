/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// NextJS Material Dashboard 2 PRO Base Styles
import colors from "/assets/theme/base/colors";
import typography from "/assets/theme/base/typography";

// NextJS Material Dashboard 2 PRO Helper Functions
import pxToRem from "/assets/theme/functions/pxToRem";

const { white, text, dark, secondary } = colors;
const { size } = typography;

const contained = {
  base: {
    backgroundColor: white.main,
    minHeight: pxToRem(40),
    color: text.main,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    "&:hover": {
      backgroundColor: white.main,
    },

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.xs,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.sm,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: dark.main,

    "&:hover": {
      backgroundColor: dark.main,
    },

    "&:focus:not(:hover)": {
      backgroundColor: dark.focus,
    },
  },

  secondary: {
    backgroundColor: secondary.main,

    "&:hover": {
      backgroundColor: secondary.main,
    },

    "&:focus:not(:hover)": {
      backgroundColor: secondary.focus,
    },
  },
};

export default contained;
