/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { createContext, useContext, useMemo, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The NextJS Material Dashboard 2 PRO main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// NextJS Material Dashboard 2 PRO reducer
function reducer(state, action) {
  switch (action.type) {
    case "SIDENAV_COLOR": return { ...state, sidenavColor: action.value };
    case "OPEN_CONFIGURATOR": return { ...state, openConfigurator: action.value };
    case "DIRECTION": return { ...state, direction: action.value };
    case "LAYOUT": return { ...state, layout: action.value };
    case "DARKMODE": return { ...state, darkMode: action.value };
    case "MOBILEVIEW": return { ...state, mobileView: action.value };
    default: throw new Error(`Unhandled action type: ${action.type}`);
  }
}

// NextJS Material Dashboard 2 PRO context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    sidenavColor: "info",
    openConfigurator: false,
    direction: "ltr",
    layout: "kit", // dashboard
    darkMode: false,
    mobileView: false,
  };
  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// NextJS Material Dashboard 2 PRO custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);
  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider.",
    );
  }
  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setMobileView = (dispatch, value) => dispatch({ type: "MOBILEVIEW", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setSidenavColor,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setMobileView
};
