/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useState, useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import breakpoints from "/assets/theme/base/breakpoints";
import theme from "/assets/theme";
import themeRTL from "/assets/theme/theme-rtl";
import themeDark from "/assets/theme-dark";
import themeDarkRTL from "/assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setOpenConfigurator,
  setMobileView
} from "/context";
import favicon from "/assets/img/favicon.png";
import appleIcon from "/assets/img/apple-icon.png";
import { Roboto } from 'next/font/google';

const SettingsRounded = dynamic(() => import('@mui/icons-material/SettingsRounded'), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const Configurator = dynamic(() => import('/examples/Configurator'), { loading: () => null });

const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin'],
  display: 'swap',
})

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createCache({ key: "css", prepend: true });

let prevRoute;
let currentRoute;
function Main({ Component, pageProps }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    openConfigurator,
    darkMode,
  } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useRouter();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={() => setOpenConfigurator(dispatch, !openConfigurator)}
    >
      <SettingsRounded fontSize="small" color="inherit" />
    </MDBox>
  );

  const router = useRouter();
  currentRoute = router.pathname

  // FB pixel
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID)
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events]);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      prevRoute = currentRoute
      currentRoute = url
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  // Mobile View Manager
  useEffect(() => {
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) setMobileView(dispatch, true);
      else setMobileView(dispatch, false);;
    }
    window.addEventListener("resize", displayMobileView);
    displayMobileView();
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);


  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        <Component {...pageProps} prevRoute={prevRoute} currentRoute={currentRoute} />
        <Configurator />
        {configsButton}
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Component {...pageProps} prevRoute={prevRoute} currentRoute={currentRoute} />
      <Configurator />
      {configsButton}
    </ThemeProvider>
  );
}
function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  return (
    <MaterialUIControllerProvider>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="shortcut icon" href={favicon.src} />
          <link rel="apple-touch-icon" sizes="76x76" href={appleIcon.src} />
          <title key="title">Autumn Leaf</title>
        </Head>
        <Main Component={Component} pageProps={pageProps} className={roboto.className} />
      </CacheProvider>
    </MaterialUIControllerProvider>
  );
}

export default MyApp;
